<template>
  <section class="bando-head">
    <h1>Gestion des zones de vacations des FSE</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">
        Création des zones de vacations pour l'établissement
        <span class="yellow">LABEGE PDC1</span>
      </h2>

      <div class="row">
        <div class="col col-md-4">
          <h3 class="mt-4">Communes rattachées :</h3>

          <draggable
            class="list-group"
            :list="communes"
            group="city"
            @change="log"
            itemKey="nom"
          >
            <template #item="{ element }">
              <div class="list-group-item">
                {{ element.cp }} {{ element.nom }}
              </div>
            </template>
          </draggable>
        </div>

        <div class="col-md-5">
          <h3>
            <img src="@/assets/img/icon-zones.png" alt="Zones de vacations" />
            Zones de vacations
          </h3>

          <div
            class="block-zone"
            v-for="zoneVacation in zonesVacations"
            :key="zoneVacation"
          >
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Nom de la zone"
                v-model="zoneVacation.nom"
                required
              />
            </div>

            <draggable
              class="villes"
              :list="zoneVacation.communes"
              group="city"
              @change="log"
              itemKey="name"
            >
              <template #item="{ element }">
                <div>{{ element.cp }} {{ element.nom }}</div>
              </template>
            </draggable>
          </div>

          <div class="text-center">
            <button
              type="submit"
              class="btn btn-primary"
              @click="addZoneVacation"
            >
              Ajouter une nouvelle zone de vacations
            </button>
            <button
              type="submit"
              class="btn btn-primary mb-4"
              @click="saveZonesVacations"
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";

export default {
  name: "ZonesVacations",
  components: {
    draggable
  },
  data() {
    return {
      communes: [],
      zonesVacations: []
    };
  },
  created() {
    axios
      .all([
        axios.get(process.env.VUE_APP_API + "/commune/list"),
        axios.get(process.env.VUE_APP_API + "/zone-vacation/list")
      ])
      .then(
        axios.spread((...responses) => {
          this.communes = responses[0].data;
          this.zonesVacations = responses[1].data;
        })
      )
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  methods: {
    addZoneVacation: function() {
      this.zonesVacations.push({
        nom: "",
        communes: []
      });
    },
    saveZonesVacations: function() {
      axios
        .post(
          process.env.VUE_APP_API + "/zone-vacation/save",
          this.zonesVacations
        )
        .then(() => {
          this.showAlert("Les zones de vacations ont bien été enregistrées.");
        })
        .catch(() => {
          this.showAlert("Une erreur s'est produite.");
        });
    },
    log: function() {
      console.log(this.zonesVacations);
    },
    showAlert(txt) {
      this.$swal.fire(txt).then();
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  h3 {
    margin-bottom: 1.5rem;

    img {
      margin-right: 1.5rem;
    }
  }

  .col {
    margin-right: 5rem;
  }

  .list-group-item {
    background: var(--navy);
    border: none;
    border-radius: 0.5rem;
    cursor: move;
    color: white;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .block-zone {
    margin-bottom: 3.5rem;
  }

  .villes {
    background: var(--lightgrey);
    border-radius: 1rem;
    box-shadow: inset 0 4px 5px rgba(black, 0.3);
    font-size: 1.3rem;
    font-weight: 600;
    padding: 1.5rem;
    text-transform: uppercase;

    div {
      cursor: move;
    }
  }

  .btn {
    display: block;
    margin: 0 auto 10rem;
  }
}
</style>
